import readingTime from "reading-time";
import wretch from "wretch";
import { render } from "datocms-structured-text-to-plain-text";
import { ArticleBySlugQuery } from "./graphql";

export function getArticleReadingTime(article: ArticleBySlugQuery) {
  let plainText = render(article.article?.content?.value);
  return readingTime(plainText || "");
}

export function handleSubscribe(email: string) {
  return wretch("/api/subscribe").post({ email }).json<{ message: string }>();
}

export function handleJoinWaitlist(params: {
  email: string;
  treatmentDate: string;
  interests: string[];
}) {
  return wretch("/api/waitlist/subscribe")
    .post(params)
    .json<{ message: string }>();
}
