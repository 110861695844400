import { AnimatePresence, motion } from "framer-motion";
import { ArrowRight, Spinner } from "phosphor-react";

let animationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export function AnimatedSubmitButton({ loading }: { loading?: boolean }) {
  return (
    <button
      disabled={loading}
      className="disabled:pointer-events-none rounded-full focus:outline-none focus:ring"
      type="submit"
    >
      <AnimatePresence initial={false} mode="wait">
        {loading ? (
          <motion.div {...animationProps} key="loader">
            <Spinner size={24} className="animate-spin" />
          </motion.div>
        ) : (
          <motion.div {...animationProps} key="submit">
            <ArrowRight size={24} className="" />
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
}
