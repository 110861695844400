import { useRouter } from "next/router";
import { useAsyncFn } from "react-use";
import { handleSubscribe } from "lib/util";
import { FormEventHandler, useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import wretch from "wretch";
import toast from "react-hot-toast";
import { useQuizResultsContext } from "pages/quiz/results";

// When the "?debug=1" query parameter is present,
// we'll overlay a 12 column grid on the page.
export function useDebugGrid() {
  const router = useRouter();
  if (!router.isReady) return;
  return router.query.debug == "1";
}

export function useSubscribe() {
  const [status, trigger] = useAsyncFn(handleSubscribe);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    let form = e.target as HTMLFormElement;
    let formData = new FormData(form);
    let email = formData.get("email") as string;
    await trigger(email);
    form.reset();

    // send an analytics event
    window?.gtag("event", "newsletter_signup", {
      screen_name: "various",
    });
  };

  useEffect(() => {
    if (status.loading) return;
    if (status.error) {
      toast.error(status.error.message);
      return;
    }
    if (status.value) {
      toast.success(status.value.message, {
        duration: 4000,
      });
      return;
    }
  }, [status]);

  return { handleSubmit, status };
}

export function useSendQuizResults() {
  const { slugs } = useQuizResultsContext();
  const [status, trigger] = useAsyncFn(async (email: string, token: string) => {
    const res = await wretch("/api/send-results")
      .post({
        email,
        token,
        slugs,
      })
      .json<{ message: string }>();
    return res;
  });

  useEffect(() => {
    if (!status.error) return;
    toast.error(status.error?.message ?? "An unexpected error occurred.", {
      duration: 4000,
    });
  }, [status.error]);

  useEffect(() => {
    if (!status.value) return;
    toast.success(status.value.message, {
      duration: 4000,
    });
  }, [status.value]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("yourAction");
    return token;
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleSubmit: React.ChangeEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    let token = await handleReCaptchaVerify();
    if (!token) {
      toast.error("Captcha validation failed.");
      return;
    }

    let formEl = e.target as HTMLFormElement;
    let formData = new FormData(formEl);
    let email = formData.get("email") as string;
    await trigger(email, token);
    formEl.reset();

    // send an analytics event
    window.gtag("event", "quiz_email_results", {
      screen_name: "quiz",
    });
  };

  let inputProps = {
    type: "email",
    name: "email",
    placeholder: "Enter your email",
    required: true,
    disabled: status.loading,
  };

  return {
    handleSubmit,
    inputProps,
    status,
  };
}

export const useSendConsultationBooking = () => {
  const [status, trigger] = useAsyncFn(
    async (
      name: string,
      email: string,
      phone: string,
      zip: string,
      preferences: string,
      url: string
    ) => {
      const res = await wretch("/api/book-consultation")
        .post({
          name,
          email,
          phone,
          zip,
          preferences,
          url,
        })
        .json<{ message: string }>();

      return res;
    }
  );

  useEffect(() => {
    if (!status.error) return;
    toast.error(status.error?.message ?? "An unexpected error occurred.", {
      duration: 4000,
    });
  }, [status.error]);

  useEffect(() => {
    if (!status.value) return;
    toast.success(status.value.message, {
      duration: 4000,
    });
  }, [status.value]);

  const handleSubmit: React.ChangeEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const formEl = e.target as HTMLFormElement;

    const formData = new FormData(formEl);
    const name = formData.get("name") as string;
    const email = formData.get("email") as string;
    const phone = formData.get("phone") as string;
    const zip = formData.get("zip") as string;
    const preferences = formData.get("preferences") as string;

    // let path of current url
    const url = window.location.href;

    await trigger(name, email, phone, zip, preferences, url);
    formEl.reset();

    // send an analytics event
    window.gtag("event", "quiz_consultation", {
      screen_name: "quiz",
    });
  };

  return {
    handleSubmit,
    status,
  };
};

// Temporary function to save zip codes that SGF does not service
export const useSendNonValidZipCode = () => {
  const [status, trigger] = useAsyncFn(
    async (
      name: string,
      email: string,
      phone: string,
      zip: string,
      preferences: string,
      url: string
    ) => {
      const res = await wretch("/api/book-consultation")
        .post({
          name,
          email,
          phone,
          zip,
          preferences,
          url,
        })
        .json<{ message: string }>();
      return res;
    }
  );

  const handleZipCodeSubmit = async (zip: number) => {
    const url = window.location.href; // let path of current url
    const tempValue = "nozipcode";

    await trigger(
      tempValue,
      tempValue,
      tempValue,
      zip.toString(),
      tempValue,
      url
    );
  };

  return {
    handleZipCodeSubmit,
  };
};
