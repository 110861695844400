import React, { useState, useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as Tooltip from "@radix-ui/react-tooltip";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { CalendarCheck, Info, X } from "phosphor-react";
import { Button } from "components/button";
import Link from "next/link";
import { useSendConsultationBooking, useSendNonValidZipCode } from "hooks";
import { validZipCodes } from "../lib/clinic-data";

const legalLinks = {
  terms: {
    label: "terms of use",
    href: "/legal/terms-of-use",
  },
  privacy: {
    label: "privacy policy",
    href: "/legal/privacy-policy",
  },
};

export function ZipCodeCheck({
  onHandleSubmission,
}: {
  onHandleSubmission: (zipcode: number) => void;
}) {
  const onSubmit: React.ChangeEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const formEl = e.target as HTMLFormElement;
    const formData = new FormData(formEl);
    const zip = formData.get("zip") as string;
    onHandleSubmission(parseInt(zip));
  };

  return (
    <>
      <div className="space-y-4 mb-4">
        <p className="font-serif text-lg">
          We partner with select U.S. clinics to fast-track patients who want to
          meet with a provider to discuss their fertility options.
        </p>
        <p className="font-serif text-lg">
          To book let's first check that you're near one of our partner clinics
          in the U.S.
        </p>
      </div>
      <Tooltip.Provider>
        <form onSubmit={onSubmit}>
          <div className="space-y-4">
            <div className="flex flex-col">
              <label
                className="opacity-70 text-sm font-medium mb-1"
                htmlFor="zip"
              >
                Zip Code
              </label>
              <input
                className="border-transparent rounded-md"
                required
                placeholder="12345"
                id="zip"
                maxLength={5}
                type="text"
                name="zip"
                pattern="[0-9]*"
                title="Enter numbers."
              />
            </div>
            <div>
              <Button full type="submit">
                Next
              </Button>
            </div>
          </div>
        </form>
      </Tooltip.Provider>
    </>
  );
}

export function ShadyGroveForm({
  zipcode,
  onHandleSubmission,
}: {
  zipcode: number;
  onHandleSubmission: () => void;
}) {
  const { handleSubmit, status } = useSendConsultationBooking();

  useEffect(() => {
    if (status.error) {
      onHandleSubmission(); // Close the dialog
    }
    if (status.value) {
      onHandleSubmission(); // Close the dialog
    }
  }, [status]);

  return (
    <>
      <p className="font-serif text-lg mb-4">
        Great! There's a clinic near you. Just fill this out to book.
      </p>
      <Tooltip.Provider>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div className="flex flex-col">
              <label
                className="opacity-70 text-sm font-medium mb-1"
                htmlFor="name"
              >
                Name
              </label>
              <input
                className="border-transparent rounded-md"
                required
                placeholder="Your name"
                id="name"
                type="text"
                name="name"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="opacity-70 text-sm font-medium mb-1"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="border-transparent rounded-md"
                required
                placeholder="hi@gmail.com"
                id="email"
                type="email"
                name="email"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="opacity-70 text-sm font-medium mb-1"
                htmlFor="phone"
              >
                Phone Number
              </label>
              <input
                className="border-transparent rounded-md"
                required
                placeholder="2058494933"
                maxLength={10}
                id="phone"
                type="tel"
                name="phone"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="opacity-70 text-sm font-medium mb-1"
                htmlFor="zip"
              >
                Zip Code
              </label>
              <input
                readOnly
                className="border-transparent rounded-md"
                required
                // defaultValue={zipcode}
                value={zipcode}
                placeholder="12345"
                id="zip"
                maxLength={5}
                type="text"
                name="zip"
                pattern="[0-9]*"
                title="Enter numbers."
              />
            </div>
            <div className="flex flex-col">
              <label
                className="opacity-70 text-sm font-medium mb-1"
                htmlFor="preferences"
              >
                What days and time are best for contacting you? (Optional but
                very helpful)
              </label>
              <textarea
                className="border-transparent rounded-md"
                rows={4}
                placeholder="Monday mornings between 2-4pm and Wednesday evenings after 5pm"
                id="preferences"
                name="preferences"
              />
            </div>
            <div className="flex items-center space-x-2">
              <input
                className="text-brand-blue rounded-sm"
                type="checkbox"
                id="tosConsent"
                required
                name="tosConsent"
              />
              <label className="select-none" htmlFor="tosConsent">
                I agree to the{" "}
                <Link href={legalLinks.terms.href}>
                  <a target="_blank" className="underline">
                    {legalLinks.terms.label}
                  </a>
                </Link>
                ,{" "}
                <Link href={legalLinks.privacy.href}>
                  <a target="_blank" className="underline">
                    {legalLinks.privacy.label}
                  </a>
                </Link>
                , and consent to providing my contact info and results to one of
                our partner clinics
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                className="text-brand-blue rounded-sm"
                type="checkbox"
                id="medicare"
                required
                name="medicare"
              />
              <label className="select-none" htmlFor="medicare">
                I do NOT have Medicaid as insurance
              </label>
              <Tooltip.Root delayDuration={0}>
                <Tooltip.Trigger type="button">
                  <Info weight="bold" />
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content className="z-30">
                    <div className="bg-brand-gray-dark text-white text-xs font-medium max-w-sm p-3 rounded-md">
                      At this time, our partner clinic is unable to accept
                      patients with Medicaid. You will NOT be able to schedule a
                      consultation if you have Medicaid.
                    </div>
                    <Tooltip.Arrow />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </div>
            <div>
              <Button full type="submit" disabled={status.loading}>
                Sign up to get a call from the new patient center.
              </Button>
            </div>
          </div>
        </form>
      </Tooltip.Provider>
    </>
  );
}

export const DefaultTrigger = (
  <Button variant="secondary">
    <div className="flex w-full items-center justify-center space-x-1">
      <CalendarCheck size={24} />
      <span>Book Consultation</span>
    </div>
  </Button>
);

export function NoZipCode({ onHandleClose }: { onHandleClose: () => void }) {
  return (
    <>
      <div className="space-y-4 mb-4">
        <p className="font-serif text-lg">
          Sorry, we don't yet have a clinic near you.
        </p>
        <p className="font-serif text-lg">
          Please check back soon! We're working to expand our clinical partners.
        </p>
        <div>
          <Button full onClick={onHandleClose}>
            Close
          </Button>
        </div>
      </div>
    </>
  );
}

export function ShadyGroveFormDialog({
  trigger = DefaultTrigger,
}: {
  trigger?: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<"zipcheck" | "canbook" | "cannotbook">(
    "zipcheck"
  );
  const [zipcode, setZipCode] = useState<number | null>(null);

  const { handleZipCodeSubmit } = useSendNonValidZipCode();

  const onHandleZipCodeCheck = (zipcode: number) => {
    if (validZipCodes.includes(zipcode)) {
      setZipCode(zipcode);
      setState("canbook");
    } else {
      handleZipCodeSubmit(zipcode);
      setState("cannotbook");
    }
  };

  const onHandleSubmission = () => {
    setOpen(false);
    setState("zipcheck");
  };

  const switchState = (state: string) => {
    switch (state) {
      case "zipcheck":
        return <ZipCodeCheck onHandleSubmission={onHandleZipCodeCheck} />;
      case "canbook":
        return (
          <ShadyGroveForm
            zipcode={zipcode ? zipcode : 12345}
            onHandleSubmission={onHandleSubmission}
          />
        );
      case "cannotbook":
        return <NoZipCode onHandleClose={onHandleSubmission} />;
      default:
        return <ZipCodeCheck onHandleSubmission={onHandleZipCodeCheck} />;
    }
  };

  return (
    <Dialog.Root
      open={open}
      onOpenChange={() => {
        if (!open === false) {
          setState("zipcheck");
        }
        setOpen(!open);
      }}
    >
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed grid inset-0 w-full bg-black/30 z-20 overflow-y-auto" />
        <Dialog.Content className="fixed bg-brand-gray rounded-md lg:left-1/2 lg:top-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 lg:max-w-xl w-full top-0 z-20 overflow-y-auto flex max-h-full lg:max-h-[94vh]">
          <Dialog.Close className="absolute top-4 right-4 rounded-full focus:outline-none focus:ring focus:ring-brand-blue">
            <X size={24} aria-hidden aria-label="Close" />
          </Dialog.Close>
          <VisuallyHidden.Root asChild>
            <Dialog.Title>Shady Grove Form</Dialog.Title>
          </VisuallyHidden.Root>

          <div className="p-8 h-full">
            <header className="space-y-1">
              <h1 className="font-serif text-3xl">
                Book a Fertility Consultation
              </h1>
            </header>
            <div className="mt-4">{switchState(state)}</div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
