import Link from "next/link";
import { Logo } from "./logo";

// TODO: potentially add this to the normal navbar component as another variant
const LogoLink = (
  <Link href="/">
    <a className="focus:ring focus:outline-none items-center text-brand-blue w-20 absolute left-4 top-4 z-[100000]">
      <Logo />
    </a>
  </Link>
);

export function NavbarQuiz() {
  return <div>{LogoLink}</div>;
}
