import { RenderSettings } from "datocms-structured-text-to-html-string";
import { BERRY_HOST_PLACEHOLDER } from "lib/constants";
import { ArticleRecord } from "lib/graphql";
import Link from "next/link";
import { Record } from "phosphor-react";
import { ReactElement } from "react";
import {
  RenderRecordLinkContext,
  StructuredTextGraphQlResponseRecord,
} from "react-datocms";

type RenderLinkToRecord<T extends { __typename: string; id: string }> = {
  react: (context: RenderRecordLinkContext<T>) => ReactElement | null;
  html: RenderSettings<T>["renderBlock"];
};

const ArticleRecord: RenderLinkToRecord<
  ArticleRecord & { __typename: string }
> = {
  html: ({ record, adapter: { renderNode } }) => {
    return renderNode(
      "a",
      {
        href: `${BERRY_HOST_PLACEHOLDER}/articles/${record.slug}`,
      },
      record.title
    );
  },
  react: ({ record, children, transformedMeta }) => {
    return (
      <Link href={`/articles/${record.slug}`}>
        <a {...transformedMeta} className="hover:underline">
          {children}
        </a>
      </Link>
    );
  },
};

const linksToRecord = {
  ArticleRecord,
};

type LinkToRecordType = keyof typeof linksToRecord;

export const renderLinkToRecord: RenderLinkToRecord<StructuredTextGraphQlResponseRecord> =
  {
    react: (context) => {
      let Component =
        linksToRecord[context.record.__typename as LinkToRecordType]?.react;
      if (!Component) {
        return (
          <div>No link to record found for {context.record.__typename}</div>
        );
      }
      return (
        <Component
          // @ts-ignore
          record={context.record}
          children={context.children}
          transformedMeta={context.transformedMeta}
        />
      );
    },
    html: (context) => {
      let fn =
        linksToRecord[context.record.__typename as LinkToRecordType]?.html;
      if (!fn) {
        return context.adapter.renderText(
          `[Link to record not found: ${context.record.__typename}]`
        );
      }
      // @ts-ignore
      return fn(context);
    },
  };
