import { AnimatedSubmitButton } from "components/animated-submit-button";
import { useSendQuizResults } from "hooks";

export function EmailForm({ text }: { text: string }) {
  // TODO: Find out a way to thread the email content through here
  // @ts-ignore
  const { status, handleSubmit, inputProps } = useSendQuizResults();

  return (
    <div className="bg-brand-blue-light rounded-3xl p-8 lg:p-12 not-prose">
      <h4 className="text-brand-gray-dark font-serif text-3xl">{text}</h4>
      <div className="mt-6">
        <form onSubmit={handleSubmit}>
          <div className="relative">
            <input
              {...inputProps}
              className="w-full border-transparent rounded-md disabled:opacity-50 text-lg pr-10"
            />
            <div className="absolute right-0 top-0 bottom-0 flex items-center justify-center px-4 text-brand-gray-dark">
              <AnimatedSubmitButton loading={status.loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
