import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { Button } from "components/button";
import { useSendQuizResults } from "hooks";

import { Envelope, X } from "phosphor-react";
import { AnimatedSubmitButton } from "./animated-submit-button";

export function EmailResultsDialog() {
  const { handleSubmit, inputProps, status } = useSendQuizResults();

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button variant="secondary">
          <div className="flex w-full items-center justify-center space-x-1">
            <Envelope size={24} />
            <span>Send results to email</span>
          </div>
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black/30" />
        <Dialog.Content className="fixed bg-brand-gray rounded-md lg:left-1/2 lg:top-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 lg:max-w-xl w-full h-full lg:h-auto top-0 z-20">
          <Dialog.Close className="absolute top-4 right-4 rounded-full focus:outline-none focus:ring focus:ring-brand-blue">
            <X size={24} aria-hidden aria-label="Close" />
          </Dialog.Close>
          <VisuallyHidden.Root asChild>
            <Dialog.Title>Shady Grove Form</Dialog.Title>
          </VisuallyHidden.Root>
          <div className="p-8">
            <header className="space-y-1">
              <h1 className="font-serif font-normal text-3xl">
                Send results to your email
              </h1>
            </header>
            <div className="mt-4">
              <form onSubmit={handleSubmit}>
                <div className="relative">
                  <input
                    {...inputProps}
                    className="w-full border-transparent rounded-md disabled:opacity-50 pr-10"
                  />
                  <div className="absolute right-0 top-0 bottom-0 flex items-center justify-center px-4 text-brand-gray-dark">
                    <AnimatedSubmitButton loading={status.loading} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
