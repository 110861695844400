export function Logo() {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 104 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.37805 4.44505V37.441C1.37805 39.3216 0 39.2646 0 40.1764C0 41.4301 1.5503 41.5441 5.22509 41.5441H7.98119C16.9385 41.5441 21.1875 37.9539 21.1875 30.1465C21.1875 24.5617 18.8907 20.8575 13.6656 18.0081C17.0533 16.4125 18.6036 14.076 18.6036 10.5997C18.6036 4.16011 14.125 0.398917 5.5696 0.398917H4.70833C1.49288 0.398917 0 0.455903 0 1.70963C0 2.56445 1.37805 2.56445 1.37805 4.44505ZM8.15344 6.5536C8.15344 4.50204 8.26828 3.64722 9.30181 3.64722C10.565 3.64722 12.4024 6.32564 12.4024 10.2578C12.4024 14.076 11.5411 15.9566 9.81858 15.9566C8.49795 15.9566 8.15344 14.9308 8.15344 12.8223V6.5536ZM8.15344 22.1682C8.15344 20.6296 8.49795 19.8887 9.35923 19.8887C11.5411 19.8887 13.7805 23.8209 13.7805 30.7734C13.7805 35.6174 12.0005 38.4098 9.876 38.4098C8.26828 38.4098 8.15344 37.384 8.15344 35.0475V22.1682Z" />
      <path d="M23.8453 41.2022H33.8362C36.4774 41.2022 37.798 42 38.2 42C39.0038 42 39.4632 41.1452 39.8651 39.6065L41.7599 32.3121C41.8748 31.9701 41.8748 31.6852 41.8748 31.4573C41.8748 30.4885 41.358 29.9756 40.5541 29.9756C39.2335 29.9756 38.6019 31.8562 36.7071 34.2497C35.0994 36.3012 33.5491 37.27 31.8265 37.27C29.9317 37.27 29.8743 36.3582 29.8743 33.8507V22.5672C29.8743 20.6866 30.2188 20.0027 31.4246 20.0027C33.3194 20.0027 34.4678 21.5984 36.4774 23.9349C36.9368 24.4478 37.4535 24.7327 37.9129 24.7327C38.7167 24.7327 39.2909 24.2198 39.2909 23.422C39.2909 22.9661 39.1761 22.3392 38.8316 21.5414L36.1903 14.8738C35.8458 13.905 35.329 13.4491 34.6974 13.4491C33.6639 13.4491 33.3768 13.905 32.9175 14.7598C32.5155 15.5577 32.1136 16.0706 31.2523 16.0706C30.2188 16.0706 29.8743 15.1018 29.8743 12.9932V6.43962C29.8743 4.78697 30.2762 4.04613 31.482 4.04613C32.86 4.04613 34.4103 5.1289 36.0181 7.35142C37.7406 9.74491 38.3148 11.7395 39.8651 11.7395C40.669 11.7395 41.1857 11.1696 41.1857 10.2578C41.1857 9.63094 41.0135 8.66214 40.669 7.35142L39.1187 1.82361C38.7742 0.683856 38.6593 0 37.6832 0C36.9942 0 35.731 0.683855 33.5491 0.683855H23.8453C22.4098 0.683855 21.6634 0.79783 21.6634 1.76662C21.6634 2.62144 22.984 2.62144 22.984 4.50204V37.384C22.984 39.2646 21.606 39.2646 21.606 40.1194C21.606 41.0882 22.4098 41.2022 23.8453 41.2022Z" />
      <path d="M50.0732 26.1004H52.657L54.9537 36.8141C55.126 37.555 55.1834 38.0109 55.1834 38.1248C55.1834 38.9227 53.8628 39.3216 53.8628 40.1764C53.8628 40.8033 54.4944 41.2022 55.8724 41.2022H61.155C62.3607 41.2022 62.9349 40.9172 62.9349 40.2904C62.9349 39.6065 62.3033 39.2646 61.7866 38.5807C61.3272 37.9539 61.155 37.099 60.9253 36.1872L58.1118 24.5047C60.9827 23.536 62.7627 20.4016 62.7627 15.8426C62.7627 6.15468 57.2505 0.341929 47.6616 0.341929C43.9294 0.341929 42.0346 0.455904 42.0346 1.76662C42.0346 2.56445 43.4126 2.62144 43.4126 4.50204V37.384C43.4126 39.2646 42.0346 39.2646 42.0346 40.1194C42.0346 41.0882 42.8384 41.2022 44.2739 41.2022H49.1545C50.5899 41.2022 51.3938 41.0882 51.3938 40.1194C51.3938 39.2646 50.0732 39.2646 50.0732 37.384V26.1004ZM50.0732 7.12347C50.0732 5.01493 50.5325 3.98914 51.6809 3.98914C53.5183 3.98914 55.8724 8.54817 55.8724 15.8426C55.8724 20.5156 54.437 23.0231 52.0254 23.0231C50.4177 23.0231 50.0732 21.8833 50.0732 19.1479V7.12347Z" />
      <path d="M72.0986 26.1004H74.6824L76.9792 36.8141C77.1514 37.555 77.2088 38.0109 77.2088 38.1248C77.2088 38.9227 75.8882 39.3216 75.8882 40.1764C75.8882 40.8033 76.5198 41.2022 77.8979 41.2022H83.1804C84.3862 41.2022 84.9603 40.9172 84.9603 40.2904C84.9603 39.6065 84.3287 39.2646 83.812 38.5807C83.3526 37.9539 83.1804 37.099 82.9507 36.1872L80.1372 24.5047C83.0081 23.536 84.7881 20.4016 84.7881 15.8426C84.7881 6.15468 79.2759 0.341929 69.687 0.341929C65.9548 0.341929 64.06 0.455904 64.06 1.76662C64.06 2.56445 65.438 2.62144 65.438 4.50204V37.384C65.438 39.2646 64.06 39.2646 64.06 40.1194C64.06 41.0882 64.8638 41.2022 66.2993 41.2022H71.1799C72.6153 41.2022 73.4192 41.0882 73.4192 40.1194C73.4192 39.2646 72.0986 39.2646 72.0986 37.384V26.1004ZM72.0986 7.12347C72.0986 5.01493 72.5579 3.98914 73.7063 3.98914C75.5437 3.98914 77.8979 8.54817 77.8979 15.8426C77.8979 20.5156 76.4624 23.0231 74.0508 23.0231C72.4431 23.0231 72.0986 21.8833 72.0986 19.1479V7.12347Z" />
      <path d="M84.4777 4.61601L90.3344 25.0746V37.384C90.3344 39.3216 88.7841 39.2076 88.7841 40.1194C88.7841 40.8033 89.4157 41.2022 90.6789 41.2022H96.8227C98.0859 41.2022 98.7749 40.8602 98.7749 40.1194C98.7749 39.2076 97.2246 39.3216 97.2246 37.384V24.3908L102.22 4.33107C102.622 2.62144 104 2.84939 104 1.76662C104 0.79783 103.541 0.683855 102.392 0.683855H98.4878C97.0523 0.683855 96.3633 0.79783 96.3633 1.82361C96.3633 2.62144 97.6839 2.90638 97.6839 4.16011C97.6839 4.27409 97.6265 4.38806 97.6265 4.50204L94.813 18.1791L90.966 4.50204C90.9085 4.33107 90.9086 4.2171 90.9086 4.10312C90.9086 2.96337 92.2866 2.56445 92.2866 1.82361C92.2866 0.79783 91.5402 0.683855 90.1047 0.683855H84.3054C83.2719 0.683855 82.7551 0.85482 82.7551 1.76662C82.7551 2.67843 84.0183 2.96337 84.4777 4.61601Z" />
    </svg>
  );
}
