import { Layout } from "components/layout";
import { renderBlock } from "components/render-block";
import { renderInlineRecord } from "components/render-inline-record";
import { renderLinkToRecord } from "components/render-link-to-record";
import { sdk } from "lib/dato";
import { flat } from "radash";
import { createContext, useContext } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { processRules } from "lib/quiz_content";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import { StructuredText } from "react-datocms";
import { ShadyGroveFormDialog } from "components/shady-grove-dialog";
import { EmailResultsDialog } from "components/email-results-dialog";

export type QuizResultsPageProps = InferGetServerSidePropsType<
  typeof getServerSideProps
>;

function ResultsBoilerplate(_: QuizResultsPageProps) {
  return (
    <div className="space-y-12">
      <div className="flex flex-col lg:flex-row lg:items-center gap-4">
        <EmailResultsDialog />
        {/* <ShadyGroveFormDialog /> */}
      </div>
      <div className="space-y-6">
        <h1 className="font-serif text-4xl">Fertility Quiz Results</h1>
        <p className="text-brand-blue font-serif italic text-3xl">
          Based on the information you provided we've collected a set of
          specific resources for you.
        </p>
      </div>
    </div>
  );
}

const QuizResultsContext = createContext<{ slugs: string[] }>({
  slugs: [],
});

export const useQuizResultsContext = () => {
  return useContext(QuizResultsContext);
};

export default function QuizResults(props: QuizResultsPageProps) {
  const { site, page, slugs } = props;
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY || ""}
    >
      <QuizResultsContext.Provider value={{ slugs }}>
        <Layout navbarVariant="static" site={site} page={[]}>
          <div className="pb-12 lg:pb-24">
            <div className="container">
              <div className="prose-dato prose-grid quiz-content">
                <ResultsBoilerplate {...props} />
                {page.allQuizContents.map((record) => {
                  return (
                    <div key={record.id}>
                      <StructuredText
                        renderBlock={renderBlock.react}
                        renderInlineRecord={renderInlineRecord.react}
                        // @ts-ignore
                        data={record?.content}
                        renderLinkToRecord={renderLinkToRecord.react}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Layout>
      </QuizResultsContext.Provider>
    </GoogleReCaptchaProvider>
  );
}

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  let processed = processRules(ctx.query);
  let slugs = flat(Object.values(processed).filter(Boolean));
  let page = await sdk.QuizResultBySlugs({ slugs });

  // For whatever reason, page.allQuizContents is not sorted by the order of slugs.
  // So let's sort it on the server side.
  page.allQuizContents = page.allQuizContents.sort((a, b) => {
    // Ignoring this, since slugs is an array of our own hard-coded strings.
    // @ts-ignore
    return slugs.indexOf(a.slug) - slugs.indexOf(b.slug);
  });

  return {
    props: {
      site: await sdk.SiteSettings(),
      page,
      slugs,
    },
  };
};
