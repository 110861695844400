import Link from "next/link";
import * as HoverCard from "@radix-ui/react-hover-card";
import {
  RenderInlineRecordContext,
  StructuredText,
  StructuredTextGraphQlResponseRecord,
} from "react-datocms";
import { renderBlock } from "./render-block";
import { ReactElement } from "react";
import { RenderSettings } from "datocms-structured-text-to-html-string";
import { ArticleRecord, TermRecord } from "lib/graphql";
import { BERRY_HOST_PLACEHOLDER } from "lib/constants";

type RenderInlineRecord<T extends StructuredTextGraphQlResponseRecord> = {
  react: ({
    record,
  }: {
    record: RenderInlineRecordContext<T>["record"];
  }) => ReactElement | null;
  html: RenderSettings<T>["renderInlineRecord"];
};

const TermRecord: RenderInlineRecord<
  TermRecord & { __typename: string; id: string }
> = {
  html: ({ record, adapter: { renderNode } }) => {
    return renderNode(
      "a",
      {
        // TODO: Fix this so that it uses the site URL as the base
        // We'll need this when we send emails
        href: `${BERRY_HOST_PLACEHOLDER}/glossary#${record.slug}`,
      },
      record.title
    );
  },
  react: ({ record }) => {
    return (
      <HoverCard.Root openDelay={100}>
        <Link passHref href={{ pathname: "/glossary", hash: record.slug }}>
          <HoverCard.Trigger>
            <span className="font-medium">{record.title}</span>
          </HoverCard.Trigger>
        </Link>
        <HoverCard.Portal>
          <HoverCard.Content className="bg-white rounded-3xl shadow-lg max-w-md">
            <div className="p-4">
              <h3 className="text-2xl font-serif mb-2">{record.title}</h3>
              <div className="prose-dato glossary-definition">
                <StructuredText
                  // @ts-ignore
                  data={record.content}
                  renderBlock={renderBlock.react}
                />
              </div>
            </div>
          </HoverCard.Content>
        </HoverCard.Portal>
      </HoverCard.Root>
    );
  },
};

const ArticleRecord: RenderInlineRecord<
  ArticleRecord & { __typename: string }
> = {
  html: ({ record, adapter: { renderNode } }) => {
    return renderNode(
      "a",
      { href: `${BERRY_HOST_PLACEHOLDER}/articles/${record.slug}` },
      record.title
    );
  },
  react: ({ record }) => {
    return <Link href={`/articles/${record.slug}`}>{record.title}</Link>;
  },
};

const inlineRecords = {
  TermRecord,
  ArticleRecord,
};

type InlineRecordType = keyof typeof inlineRecords;

export const renderInlineRecord: RenderInlineRecord<StructuredTextGraphQlResponseRecord> =
  {
    html: (context) => {
      let fn =
        inlineRecords[context.record.__typename as InlineRecordType]?.html;
      if (!fn) {
        return context.adapter.renderText(
          `[Inline record not found: ${context.record.__typename}]`
        );
      }
      // @ts-ignore
      return fn(context);
    },
    react: (context) => {
      let Component =
        inlineRecords[context.record.__typename as InlineRecordType]?.react;
      if (!Component) {
        return (
          <div>No inline record found for {context.record.__typename} </div>
        );
      }
      // @ts-ignore
      return <Component record={context.record} />;
    },
  };
