import { Button } from "components/button";
import { ShadyGroveFormDialog } from "components/shady-grove-dialog";

export function ConsultationButton({
  text,
  buttonText,
}: {
  buttonText: string;
  text: string;
}) {
  return (
    <div className="bg-brand-blue-light rounded-3xl p-8 lg:p-12">
      <h4 className="text-brand-gray-dark font-serif text-3xl">{text}</h4>
      <div className="mt-6">
        <ShadyGroveFormDialog
          trigger={<Button full>{buttonText}</Button>}
        ></ShadyGroveFormDialog>
      </div>
    </div>
  );
}
