export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export interface Interest {
  category_id: string;
  list_id: string;
  id: string;
  name: string;
  subscriber_count: string;
  display_order: number;
}

interface MergeFieldOptions {
  choices: string[];
}

export interface MergeField {
  merge_id: number;
  tag: string;
  name: string;
  type: string;
  required: boolean;
  default_value: string;
  public: boolean;
  display_order: number;
  options: MergeFieldOptions;
  help_text: string;
  list_id: string;
}
