import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import Link from "next/link";
import { useRouter } from "next/router";
import { CaretDown } from "phosphor-react";

import { NavbarLink } from "./navbar";
import { Button } from "./button";
import { MouseEventHandler } from "react";

const WrappedLink = (props: NavigationMenu.NavigationMenuLinkProps) => {
  const router = useRouter();
  const isActive = router.asPath === props.href;
  let mergedProps = {
    ...props,
    className: `${props.className} ${isActive ? "underline" : ""}`,
  };

  return (
    // @ts-ignore
    <Link href={props.href} passHref>
      <NavigationMenu.Link
        active={isActive}
        {...mergedProps}
        onClick={props.onClick}
      />
    </Link>
  );
};

const WrappedButtonLink = (props: NavigationMenu.NavigationMenuLinkProps) => {
  const router = useRouter();
  const isActive = router.asPath === props.href;
  const onClick = props.onClick;

  return (
    <>
      {onClick ? (
        <NavigationMenu.Link asChild active={isActive} {...props}>
          <Button
            variant="primary"
            onClick={onClick as unknown as MouseEventHandler<HTMLButtonElement>}
          >
            {props.children}
          </Button>
        </NavigationMenu.Link>
      ) : (
        // @ts-ignore
        <Link href={props.href} passHref onClick={onClick}>
          <NavigationMenu.Link asChild active={isActive} {...props}>
            {/* @ts-ignore */}
            <Button variant="primary" as="a">
              {props.children}
            </Button>
          </NavigationMenu.Link>
        </Link>
      )}
    </>
  );
};

export function DesktopMenu({ links }: { links: NavbarLink[] }) {
  return (
    <NavigationMenu.Root>
      <NavigationMenu.List className="flex items-center space-x-10">
        {links.map((link, i) => {
          if (link.children) {
            return (
              <NavigationMenu.Item key={i} className="relative">
                <NavigationMenu.Trigger className="nav-link items-center group">
                  <span>{link.label}</span>
                  <CaretDown
                    size={24}
                    className="ml-2 flex-shrink-0 group-radix-state-open:rotate-180 transform"
                  />
                </NavigationMenu.Trigger>
                <NavigationMenu.Content className="absolute left-0 right-0 top-[calc(100%+.5rem)] w-full min-w-[120px]">
                  <NavigationMenu.Sub
                    className="bg-white rounded-3xl"
                    orientation="vertical"
                  >
                    <NavigationMenu.List>
                      {link.children.map((child, ci) => {
                        return (
                          <NavigationMenu.Item key={ci}>
                            <WrappedLink
                              href={child.href}
                              className="nav-link p-4"
                            >
                              {child.label}
                            </WrappedLink>
                          </NavigationMenu.Item>
                        );
                      })}
                    </NavigationMenu.List>
                  </NavigationMenu.Sub>
                </NavigationMenu.Content>
              </NavigationMenu.Item>
            );
          } else if (link.type === "nav") {
            return (
              <NavigationMenu.Item key={i}>
                <WrappedLink
                  href={link.href}
                  className="nav-link"
                  onClick={link.onClick}
                >
                  {link.label}
                </WrappedLink>
              </NavigationMenu.Item>
            );
          } else if (link.type === "cta") {
            return (
              <NavigationMenu.Item key={i}>
                <WrappedButtonLink href={link.href} onClick={link.onClick}>
                  {link.label}
                </WrappedButtonLink>
              </NavigationMenu.Item>
            );
          }
        })}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}
