import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/source-serif-pro/400.css";
import "@fontsource/source-serif-pro/400-italic.css";
import "@fontsource/source-serif-pro/700.css";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { GridOverlay } from "components/grid-overlay";
import { Toaster } from "react-hot-toast";
import Script from "next/script";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { Analytics } from "@vercel/analytics/react";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    startIntercom();

    const handleRouteChange = (url: string) => {
      // we need to hide intercom entirely on the quiz page
      toggleIntercom(
        url === "/quiz" || url === "/feedback" || url === "/treatment-guide"
          ? false
          : true
      );
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <>
      <GridOverlay />
      <Component {...pageProps} />
      <Toaster />
      <Analytics />
      {loadGoogleAnalytics()}
      {loadIntercom()}
      {loadHotjar()}
    </>
  );
}

// Scripts that use the afterInteractive strategy are injected client-side and will run after Next.js
// hydrates the page. This strategy should be used for scripts that do not need to load as soon as
// possible and can be fetched and executed immediately after the page is interactive.
function loadHotjar() {
  return (
    <Script id="hotjar" strategy="afterInteractive">
      {`
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
    </Script>
  );
}

function loadGoogleAnalytics() {
  return (
    <>
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="google-gtag" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
          });
    `}
      </Script>
    </>
  );
}

// Intercom chat: Intercom suggestion is to add this before the /head tag on react pages
// Next.js: Scripts with beforeInteractive will always be injected inside the head of the HTML document regardless of where it's placed in _document.js.
function loadIntercom() {
  return (
    <Script id="intercom" strategy="beforeInteractive">
      {`
          (function() {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i = function() {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function(args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var l = function() {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}';
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === 'complete') {
                    l();
                } else if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();
        `}
    </Script>
  );
}

function startIntercom() {
  const urlParams = new URLSearchParams(window.location.search);
  const fullscreen = urlParams.get("fullscreen");

  // only boot up if quiz is not the first page users land
  if (
    !(window.location.pathname === "/quiz") &&
    !(window.location.pathname === "/feedback") &&
    !(window.location.pathname === "/treatment-guide") &&
    !window.location.pathname.includes("/app") &&
    !(fullscreen === "true") &&
    !document.body.classList.contains("fullscreen")
  ) {
    (window as any).Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    });
  }
}

// Toggle showing the full intercom widget
// true = show, false = don't show
function toggleIntercom(status: boolean) {
  (window as any).Intercom("update", {
    hide_default_launcher: !status, // hides the little bubble
  });

  // we need to hide the container intercom div for outbound messages
  const intercomEl = document.querySelector(
    "#intercom-container"
  ) as HTMLElement;
  if (intercomEl) {
    if (status) {
      intercomEl.style.display = "block";
    } else {
      intercomEl.style.display = "none";
    }
  }
}

export default MyApp;
