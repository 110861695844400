import { ReactElement } from "react";
import {
  Image,
  RenderBlockContext,
  StructuredText,
  StructuredTextGraphQlResponseRecord,
} from "react-datocms";
import { RenderSettings } from "datocms-structured-text-to-html-string";
import { ConsultationButton } from "./blocks/consultation-button";
import { render as renderPlain } from "datocms-structured-text-to-plain-text";
import { EmailForm } from "./blocks/email-form";
import {
  ButtonRecord,
  CalloutRecord,
  ConsultationButtonRecord,
  EmailFormRecord,
  ImageBlockHorizontalRecord,
  ImageBlockVerticalRecord,
  TipRecord,
} from "lib/graphql";
import { Button } from "./button";

type RenderBlock<T extends { __typename: string; id: string }> = {
  react: ({
    record,
  }: {
    record: RenderBlockContext<T>["record"];
  }) => ReactElement | null;
  html: RenderSettings<T>["renderBlock"];
};

// Blocks
const CalloutRecord: RenderBlock<CalloutRecord & { __typename: string }> = {
  react: ({ record }) => {
    return (
      <div className="block bg-brand-gray px-8 py-3 my-5 rounded-3xl prose callout-content">
        <StructuredText data={record.content.value}></StructuredText>
      </div>
    );
  },

  html: ({ record, adapter: { renderText } }) => {
    return renderText(renderPlain(record.content.value) || "");
  },
};

const ImageBlockVerticalRecord: RenderBlock<
  ImageBlockVerticalRecord & { __typename: string }
> = {
  html: ({ record, adapter: { renderNode } }) => {
    if (!record.image.responsiveImage) return null;
    return renderNode("img", {
      src: record.image.responsiveImage?.src,
      alt: record.image.responsiveImage?.alt || "",
      style: "max-width: 700px;",
    });
  },
  react: ({ record }) => {
    if (!record.image.responsiveImage) return null;
    return (
      <div className="not-prose block-wide lg:w-full w-full">
        <Image data={record.image.responsiveImage} className="m-auto" />
        <p className="text-sm text-center mt-4 mb-4">
          {record.image.responsiveImage?.title}
        </p>
      </div>
    );
  },
};

const ImageBlockHorizontalRecord: RenderBlock<
  ImageBlockHorizontalRecord & { __typename: string }
> = {
  html: ({ record, adapter: { renderNode } }) => {
    return renderNode(
      "img",
      {
        // @ts-ignore
        src: record.image.responsiveImage?.src,
        alt: record.image.responsiveImage?.alt || "",
        style: "max-width: 700px;",
      },
      ""
    );
  },
  react: ({ record }: RenderBlockContext<any>) => {
    return (
      <div className="not-prose w-full block-wide">
        <Image data={record.image.responsiveImage} className="m-auto" />
        <p className="text-sm text-center mt-4 mb-4">
          {record.image.responsiveImage?.title}
        </p>
      </div>
    );
  },
};

const TipRecord: RenderBlock<TipRecord & { __typename: string }> = {
  html: ({ record, adapter: { renderText } }) => {
    return renderText(renderPlain(record.content.value) || "");
  },
  react: ({ record }) => {
    return (
      <div className="not-prose p-6 bg-brand-yellow my-5 rounded-3xl">
        <div className="flex flex-col gap-1">
          <span className="flex-shrink-0 font-medium">TIP</span>
          <StructuredText data={record.content.value}></StructuredText>
        </div>
      </div>
    );
  },
};

const ButtonRecord: RenderBlock<ButtonRecord & { __typename: string }> = {
  html: () => {
    // No need to render this in the email.
    return null;
  },
  react: ({ record }) => {
    return (
      <div className="not-prose my-5">
        {/* TODO(Matt): Support link field */}
        <Button variant="primary">{record.text}</Button>
      </div>
    );
  },
};

const ConsultationButtonRecord: RenderBlock<
  ConsultationButtonRecord & { __typename: string }
> = {
  html: () => {
    // No need to render this in the email.
    return null;
  },
  react: ({ record }) => {
    return (
      <div className="not-prose my-5">
        <ConsultationButton {...record} />
      </div>
    );
  },
};

const EmailFormRecord: RenderBlock<EmailFormRecord & { __typename: string }> = {
  html: () => {
    // No need to render this in the email.
    return null;
  },
  react: ({ record }) => {
    return (
      <div className="not-prose my-5">
        <EmailForm {...record} />
      </div>
    );
  },
};

const blocks = {
  CalloutRecord,
  ImageBlockVerticalRecord,
  ImageBlockHorizontalRecord,
  TipRecord,
  ButtonRecord,
  ConsultationButtonRecord,
  EmailFormRecord,
};

type BlockType = keyof typeof blocks;

export const renderBlock: RenderBlock<StructuredTextGraphQlResponseRecord> = {
  // Registering independent serializers for the different target types, React (front-end) and HTML (email).
  react: (context) => {
    let Component = blocks[context.record.__typename as BlockType]?.react;
    if (!Component) {
      return <div>No block found for {context.record.__typename}</div>;
    }
    // @ts-ignore
    return <Component record={context.record} />;
  },
  html: (context) => {
    let fn = blocks[context.record.__typename as BlockType]?.html;
    if (!fn) {
      return context.adapter.renderText(
        `[Block record not found: ${context.record.__typename}]`
      );
    }
    // @ts-ignore
    return fn(context);
  },
};
