import { useDebugGrid } from "../hooks";

export function GridOverlay() {
  const debug = useDebugGrid();
  if (!debug) return null;

  return (
    <div className="fixed w-full h-screen inset-0 z-10 pointer-events-none">
      <div className="container relative h-full pointer-events-none">
        <div className="h-full site-grid inset-0">
          {Array.from({ length: 12 }).map((_, i) => {
            return <div key={i} className="col-span-1 bg-red-200/20" />;
          })}
        </div>
      </div>
    </div>
  );
}
