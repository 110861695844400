import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { List, X } from "phosphor-react";
import { NavbarLink } from "components/navbar";
import Link from "next/link";
import { Button } from "./button";
import { Logo } from "./logo";
import { useState } from "react";

export function MobileMenu({ links }: { links: NavbarLink[] }) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger className="inline-flex items-center space-x-2 py-2">
        <List aria-hidden size={28} />
        {/* <span className="font-medium">Menu</span> */}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="lg:hidden fixed inset-0 w-full h-full" />
        <Dialog.Content className="lg:hidden fixed inset-0 w-full h-full bg-brand-gray z-10">
          <VisuallyHidden.Root asChild>
            <Dialog.Title>Mobile navigation Menu</Dialog.Title>
          </VisuallyHidden.Root>
          <div className="container">
            <div className="flex items-center justify-between py-8">
              <Dialog.Close className="flex items-center space-x-2 py-2">
                <X size={28} aria-hidden />
                <span className="font-medium">Close</span>
              </Dialog.Close>
              <div className="w-20 text-brand-blue absolute left-0 right-0 ml-auto mr-auto">
                <Logo />
              </div>
            </div>
            <div className="mt-8">
              <ul className="flex flex-col space-y-4">
                {links.map((link, i) => {
                  if (link.children) {
                    return (
                      <li key={i}>
                        <ul className="flex flex-col space-y-4">
                          {link.children.map((child, ci) => (
                            <Link key={ci} href={child.href}>
                              <a className="nav-link text-2xl">{child.label}</a>
                            </Link>
                          ))}
                        </ul>
                      </li>
                    );
                  } else if (link.type === "nav") {
                    return (
                      <li key={i}>
                        <Link href={link.href}>
                          <a
                            className="nav-link text-2xl"
                            onClick={link.onClick}
                          >
                            {link.label}
                          </a>
                        </Link>
                      </li>
                    );
                  } else if (link.type === "cta") {
                    return (
                      <li key={i}>
                        <Button
                          as="a"
                          variant="primary"
                          size="small"
                          onClick={() => {
                            setOpen(false);
                            if (link.onClick) link.onClick();
                          }}
                        >
                          {link.label}
                        </Button>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
