import { SiteSettingsQuery } from "lib/graphql";
import Head from "next/head";
import React, { useEffect } from "react";
import { renderMetaTags } from "react-datocms";
import { Footer } from "components/footer";
import { Navbar, NavbarProps } from "./navbar";
import { NavbarQuiz } from "./navbar-quiz";

export function Layout({
  navbarVariant,
  page,
  site,
  children,
  showNavbar = true,
  showNavbarBackground = false,
  showLogo = false,
  showFooter = true,
}: {
  children: React.ReactNode;
  site: SiteSettingsQuery;
  // TODO(matt): figure out the type for this
  page: any;
  navbarVariant?: NavbarProps["variant"];
  showNavbar?: boolean; // for webviews from the Berry app, to hide all header, logo and footer
  showNavbarBackground?: boolean; // using in case someone links to a page with an anchor and we have to make header non-transparent
  showLogo?: boolean; // for the quiz page where we only want to show the logo, no footer
  showFooter?: boolean;
}) {
  const [paddingTop, setPaddingTop] = React.useState("pt-32 lg:pt-40");
  useEffect(() => {
    setPaddingTop(showNavbar ? "pt-32 lg:pt-40" : "pt-4 lg:pt-16");
  }, [showNavbar]);

  // to simulate fullscreen coming from the Berry App.
  // useEffect(() => {
  //   document.body.classList.add("fullscreen");
  // });

  return (
    <>
      <Head>
        {renderMetaTags([...page, ...site.site.favicon])}
        <meta name="apple-itunes-app" content="app-id=6444190877" />
      </Head>
      {showNavbar ? (
        <Navbar variant={navbarVariant} showBackground={showNavbarBackground} />
      ) : null}
      {showLogo ? <NavbarQuiz /> : null}
      <main className={navbarVariant === "static" ? paddingTop : ""}>
        {children}
      </main>
      {showFooter ? <Footer /> : null}
    </>
  );
}
