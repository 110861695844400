import { APPLE_APP_STORE, GOOGLE_PLAY_STORE } from 'lib/public-urls';

export const gaAnalytics = () => {
  const triggerTakeQuiz = () => {
    const screenName = window.location.pathname + window.location.search;
    window.gtag("event", "take_quiz_button", {
      screen_name: screenName,
    });
  };

  type PlatformType = 'iOS' | 'Android';

  const triggerGetApp = () => {
      const screenName = window.location.pathname + window.location.search;
      window.gtag("event", "get_app_button", {
        screen_name: screenName,
      });
    }; 

  return { triggerTakeQuiz, triggerGetApp };
};