import React from "react";

type PolymorphicRef<C extends React.ElementType> =
  React.ComponentPropsWithRef<C>["ref"];

type AsProp<C extends React.ElementType> = {
  as?: C;
};

type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P);

type PolymorphicComponentProp<
  C extends React.ElementType,
  Props = {}
> = React.PropsWithChildren<Props & AsProp<C>> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

type PolymorphicComponentPropWithRef<
  C extends React.ElementType,
  Props = {}
> = PolymorphicComponentProp<C, Props> & { ref?: PolymorphicRef<C> };

type ButtonVariant = "primary" | "secondary" | "tertiary" | "black";
type ButtonSize = "small" | "large";

type ButtonProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  { variant?: ButtonVariant; size?: ButtonSize; full?: boolean }
>;

type ButtonComponent = <C extends React.ElementType = "button">(
  props: ButtonProps<C>
) => React.ReactElement | null;

export const Button: ButtonComponent = React.forwardRef(
  <C extends React.ElementType = "button">(
    {
      as,
      size = "small",
      variant = "primary",
      full,
      children,
      ...restProps
    }: ButtonProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || "button";

    const buttonClass = `btn btn--${variant} btn--${size} ${
      full ? "btn--full" : ""
    }`;

    return (
      <Component ref={ref} className={buttonClass} {...restProps}>
        {children}
      </Component>
    );
  }
);
